@font-face {
	font-family: 'Gin';
	src:
		url('./fonts/Gin/Gin-Regular.woff2') format('woff2'),
		url('./fonts/Gin/Gin-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@each $name, $weight, $style in ((Regular, 400, normal), (Bold, 700, normal), (RegularItalic, 400, italic)) {
	@font-face {
		font-family: 'Pitch';
		src:
			url('./fonts/Pitch/Pitch-#{$name}.woff2') format('woff2'),
			url('./fonts/Pitch/Pitch-#{$name}.woff') format('woff');
		font-weight: $weight;
		font-style: $style;
	}
}

:root {
	--grid-gutter: 10px;
	--headerHeight: 120px;
	--body-color: #fff;
	--link-color: #fff;
	--accent-color: #ffff00;
}

::selection {
	background: var(--accent-color);
	color: #000;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
}

body {
	margin: 0;
	color: var(--body-color);
	background: url('./bg-tile.png') #000 center center;
	background-size: 220px;
}

body,
input,
button {
	font-family: Pitch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
	color: var(--link-color);
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

@media print {
	:root {
		--body-color: #000;
		--link-color: #000;
	}
	* {
		background: none !important;
	}
}
